import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, forkJoin, from, Observable } from 'rxjs';
import { take, map, switchMap } from 'rxjs/operators';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { UniHttp } from '../../../framework/core/http/http';
import { HttpClient } from '@angular/common/http';
import { NumberFormat } from './numberFormatService';
import { AuthService } from '../../authService';
import {
    Leavetype,
    LimitType,
    SpecialTaxAndContributionsRule,
    ShipRegistry,
    ShipTradeArea,
    ShipTypeOfShip,
    WorkingHoursScheme,
    RemunerationType,
    TypeOfEmployment,
    SharingType,
    StatusCodeSharing,
    TaxType,
    StdWageType,
    SpecialAgaRule,
    SupplierInvoiceOriginType,
    StatusCodeReInvoice,
} from '../../unientities';
import { ErrorService } from './errorService';
import { StatusService } from './statusService';
import { CompanySettingsService } from './companySettingsService';
import { CompanySettings } from '../../unientities';

import { rigDate } from '@app/components/common/utils/rig-date';

import { cloneDeep } from 'lodash-es';
import { ColumnTemplateOverrides } from '@app/components/uniticker/ticker/column-template-overrides';
import { QuickFilter } from '@uni-framework/ui/unitable';
import { theme, THEMES } from 'src/themes/theme';
import { InvoiceTypes } from '@app/models/sales/invoiceTypes';

import * as allModels from '../../unientities';

@Injectable({ providedIn: 'root' })
export class UniTickerService {
    private tickers: Array<Ticker>;
    private companySettings: CompanySettings;

    constructor(
        private http: HttpClient,
        private uniHttp: UniHttp,
        private numberFormatService: NumberFormat,
        private authService: AuthService,
        private router: Router,
        private statusService: StatusService,
        private errorService: ErrorService,
        private companySettingsService: CompanySettingsService,
    ) {
        /* KE: We dont have a backend endpoint yet - consider this later
               when we have stabilized the JSON structure for tickers

        super(http);

        this.relativeURL = UniQueryDefinition.RelativeUrl;
        this.DefaultOrderBy = null;
        this.entityType = UniQueryDefinition.EntityType;
        */

        if (this.authService) {
            this.authService.authentication$.subscribe((change) => this.invalidateCache());
        }
    }

    private invalidateCache() {
        this.tickers = null;
        this.companySettings = null;
    }

    public loadTickerCache(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.companySettingsService.getCompanySettings().subscribe((companySettings) => {
                this.companySettings = companySettings;

                this.statusService.loadStatusCache().then(() => {
                    if (this.tickers) {
                        resolve('');
                    } else {
                        forkJoin([
                            this.http.get('assets/tickers/accountingtickers.json', { observe: 'body' }),
                            this.http.get('assets/tickers/salestickers.json', { observe: 'body' }),
                            this.http.get('assets/tickers/toftickers.json', { observe: 'body' }),
                            this.http.get('assets/tickers/timetickers.json', { observe: 'body' }),
                            this.http.get('assets/tickers/salarytickers.json', { observe: 'body' }),
                            this.http.get('assets/tickers/sharedtickers.json', { observe: 'body' }),
                            this.http.get('assets/tickers/banktickers.json', { observe: 'body' }),
                        ])
                            .pipe(
                                switchMap((tickerFiles) => {
                                    const tickers = [].concat(...tickerFiles);
                                    return this.filterTickersByPermissions(tickers);
                                }),
                                map((tickers: Ticker[]) => {
                                    tickers.forEach((ticker) => {
                                        ticker.Type = ticker.Type?.toLowerCase() || 'table';

                                        ticker.Actions?.forEach((action) => {
                                            action.Type = action.Type?.toLowerCase() || '';
                                            action.Options = action.Options || {};
                                            action.Options.ParameterProperty = action.Options.ParameterProperty || '';
                                        });

                                        // Prefix all field filters with model name
                                        if (ticker.Model && ticker.Filters) {
                                            ticker.Filters.forEach((filter) => {
                                                filter.FilterGroups?.forEach((group) => {
                                                    group.FieldFilters?.forEach((field) => {
                                                        if (!this.isFunction(field.Field)) {
                                                            if (field.Field.indexOf('.') === -1) {
                                                                field.Field = ticker.Model + '.' + field.Field;
                                                            }
                                                        }
                                                    });
                                                });
                                            });
                                        }

                                        // Copy paste from old init code. Not sure if/why it's needed, probably some null pointer stuff.
                                        if (!ticker.Filters?.length) {
                                            ticker.Filters = [
                                                <TickerFilter>{
                                                    Name: 'Egendefinert',
                                                    Code: ticker.Model + 'CustomSearch',
                                                    FilterGroups: [],
                                                    IsActive: false,
                                                },
                                            ];
                                        }

                                        ticker.Columns?.forEach((column) => {
                                            column.Type = column.Type?.toLowerCase() || '';

                                            this.setupFieldProperties(column, ticker);
                                            column.SubFields?.forEach((subField) =>
                                                this.setupFieldProperties(subField, ticker),
                                            );
                                        });
                                    });

                                    // Add sub tickers to their respective parent tickers.
                                    // This needs to happen after the forEach above, since we need to loop through all tickers
                                    // and set necessary properties before we start cloning sub tickers.
                                    tickers.forEach((ticker) => {
                                        ticker.SubTickers = ticker.SubTickers || [];
                                        ticker.SubTickersCodes?.forEach((subTickerCode) => {
                                            if (!ticker.SubTickers.find((t) => t.Code === subTickerCode)) {
                                                const subTicker = tickers.find((x) => x.Code === subTickerCode);
                                                if (subTicker) {
                                                    ticker.SubTickers.push(cloneDeep(subTicker));
                                                }
                                            }
                                        });
                                    });

                                    return tickers;
                                }),
                            )
                            .subscribe(
                                (tickers) => {
                                    this.tickers = tickers;
                                    resolve('');
                                },
                                (err) => reject(err),
                            );
                    }
                });
            });
        });
    }

    private setupFieldProperties(c: TickerColumn, t: Ticker) {
        if (!c.Header) {
            // TODO: Get header based on translation
            c.Header = c.Field;
        }

        if (c.Header.indexOf('[BASECURRENCY]') !== -1) {
            let baseCurrencyCode;

            if (this.companySettings && this.companySettings.BaseCurrencyCode) {
                baseCurrencyCode = this.companySettings.BaseCurrencyCode.Code || 'Hovedvaluta';
            } else {
                baseCurrencyCode = 'Hovedvaluta';
            }

            c.Header = c.Header.replace('[BASECURRENCY]', baseCurrencyCode);
        }

        if (typeof c.DefaultHidden !== 'boolean') {
            c.DefaultHidden = false;
        }

        // TODO: tar ikke hensyn til f.eks. Customer.CustomerNumber her - sjekker
        // bare på hoved nivå.
        // Må utvide til å også sjekke path og finne modell basert på den
        let colName = c.Field;
        let aliasColName = '';
        let selectableColName = '';

        const modelname = t.Model ? t.Model : '';

        if (this.isFunction(c.Field)) {
            // for functions, trust that the user knows what he/she is doing...
            selectableColName = colName;
            aliasColName = modelname + colName;
        } else if (c.Field.indexOf('.') > 0) {
            // get last part of path, e.g. field.Field = Customer.Info.Name,
            // gets "Info" and "Name"
            let lastIndex = c.Field.lastIndexOf('.');
            let path = c.Field.substring(0, lastIndex);
            if (path.indexOf('.') > 0) {
                lastIndex = path.lastIndexOf('.');
                path = path.substring(lastIndex + 1);
            }

            colName = c.Field.substring(c.Field.lastIndexOf('.') + 1);

            selectableColName = path + '.' + colName;
            aliasColName = path + colName;
        } else {
            selectableColName = modelname + '.' + colName;
            aliasColName = modelname + colName;
        }

        if (c.SumFunction && selectableColName.indexOf(c.SumFunction) === -1) {
            selectableColName = `${c.SumFunction}(${selectableColName})`;
        }

        // set the Alias we are using in the query to simplify
        // getting the data later on
        c.Alias = c.Alias ? c.Alias : aliasColName;
        c.SelectableFieldName = selectableColName;
    }

    private isFunction(field: string): boolean {
        return field.indexOf('(') > -1 && field.indexOf(')') > -1;
    }

    public getTickers(): Promise<Ticker[]> {
        return this.loadTickerCache().then(() => {
            this.tickers = this.tickers.map((t) => {
                // Kind of dirty hack to remove filters from supplier invoice and customer invoice list based on company settings value

                if (t.Code.includes('invoice_list') && theme.theme === THEMES.EXT02) {
                    t.Filters = t.Filters.map((f) => {
                        if (
                            f.Code === 'invoices_reminded' ||
                            f.Code === 'invoices_sentforcollection' ||
                            f.Code === 'my_invoices'
                        ) {
                            f.hidden = true;
                        }
                        return f;
                    });
                }
                return t;
            });

            return this.tickers;
        });
    }

    public getTicker(code: string): Observable<Ticker> {
        return from(this.getTickers()).pipe(map((tickers) => tickers.find((ticker) => ticker.Code === code)));
    }

    public executeAction(action: TickerAction, ticker: Ticker, selectedRows: Array<any>): Promise<any> {
        return new Promise((resolve, reject) => {
            const uniEntityClass = allModels[ticker.Model];

            if (action.Type === 'new') {
                let url = linkUrls[ticker.Model];
                if (url) {
                    url = url.replace(':ID', '0');
                    this.router.navigateByUrl(url);
                } else {
                    throw Error('Could not navigate, no URL specified for model ' + ticker.Model);
                }
            } else if (action.Type === 'details') {
                const rowId: number = null;
                const urlIdProperty: string = 'ID';
                let propValuePairs: { prop: string; value: any }[] = [{ prop: urlIdProperty, value: rowId }];

                // check that we can find the ID of the model - and that we have only one
                if (!selectedRows || selectedRows.length !== 1) {
                    throw Error('Could not navigate, not possible to find ID to navigate to');
                } else {
                    if (action.Options.ParameterProperty !== '') {
                        propValuePairs = [
                            {
                                prop: action.Options.ParameterProperty.toLowerCase(),
                                value: selectedRows[0][action.Options.ParameterProperty.replace('.', '')],
                            },
                        ];
                    } else if (action.Options.ParameterProperties && action.Options.ParameterProperties.length) {
                        propValuePairs = [];
                        action.Options.ParameterProperties.forEach((prop) => {
                            propValuePairs.push({
                                prop: prop.toLowerCase().replace('.', ''),
                                value: selectedRows[0][prop.replace('.', '')],
                            });
                        });
                    } else {
                        propValuePairs[0].value = selectedRows[0] && selectedRows[0]['ID'];
                    }
                }

                // get url for new entity, navigate
                let url = linkUrls[ticker.Model];
                if (url) {
                    propValuePairs.forEach((pair) => {
                        url = url.replace(`:${pair.prop}`, pair.value.toString());
                    });
                    this.router.navigateByUrl(url);
                } else {
                    throw Error('Could not navigate, no URL specified for model ' + ticker.Model);
                }
            } else if (action.Type === 'action') {
                console.error('actions with Type = "action" are not impelmented yet', action, ticker, selectedRows);
            } else if (action.Type === 'transition') {
                if (!uniEntityClass) {
                    throw Error('Cannot find unientity class for model ' + ticker.Model + ', cannot run transition');
                } else if (!uniEntityClass.RelativeUrl || uniEntityClass.RelativeUrl === '') {
                    throw Error(
                        'No URL defined for unientity class for model ' + ticker.Model + ', cannot run transition',
                    );
                }

                // check that we can find the ID of the model - and that we have at least one
                if (!selectedRows || selectedRows.length === 0) {
                    throw Error('No row selected, cannot execute transition ' + action.Options.Transition);
                }

                const service = new BizHttp<any>(this.uniHttp);
                service.relativeURL = uniEntityClass.RelativeUrl;

                // TBD: should consider some throttling here if a lot of rows are selected - could potentially
                // start hundreds of requests - errors should probably also be handled better, but it
                // is probably not optimal to run requests one-by-one either.
                const requests = [];
                selectedRows.forEach((row) => {
                    requests.push(service.Transition(row['ID'], row, action.Options.Transition));

                    if (!row._links.transitions[action.Options.Transition]) {
                        reject(
                            `Cannot execute transition ${action.Options.Transition}` +
                                ` for ID ${row['ID']}, transition is not available for this item`,
                        );
                    }

                    console.log(`Transition ${action.Options.Transition} queued for ID ${row['ID']}`);
                });

                forkJoin(requests).subscribe(
                    (response) => {
                        resolve('');
                    },
                    (err) => {
                        reject(`Error executing transition ${action.Options.Transition}`);
                        this.errorService.handle(err);
                    },
                );
            }
        });
    }

    public filterTickersByPermissions(tickers: Ticker[]): Observable<Ticker[]> {
        if (!tickers || !tickers.length) {
            return of([]);
        }

        return this.uniHttp.authService.authentication$.pipe(
            take(1),
            map((auth) => {
                const permissions = (auth.user && auth.user['Permissions']) || [];
                if (!permissions.length) {
                    return tickers;
                }

                const filtered = tickers.filter((ticker) => {
                    if (!ticker.RequiredUIPermissions || !ticker.RequiredUIPermissions.length) {
                        return true;
                    }

                    return ticker.RequiredUIPermissions.every((permission) => {
                        const splitPermisions = permission.split('||');
                        if (splitPermisions.length > 1) {
                            // Split permissions are separated by 'or', so check if any of them
                            // match a user permission (.some(..))
                            return splitPermisions.some((p) => this.authService.hasUIPermission(auth.user, p));
                        } else {
                            return this.authService.hasUIPermission(auth.user, permission);
                        }
                    });
                });

                return filtered;
            }),
        );
    }

    public getGroupedTopLevelTickers(tickers: Ticker[]): Array<TickerGroup> {
        const groups: Array<TickerGroup> = [];

        for (const ticker of tickers.filter((x) => x.IsTopLevelTicker)) {
            const groupName = ticker.Group;

            let group = groups.find((g) => g.Name === groupName);

            if (!group) {
                group = {
                    Name: ticker.Group,
                    Tickers: [],
                };

                groups.push(group);
            }

            group.Tickers.push(ticker);
        }

        return groups;
    }

    public getFieldValue(column: TickerColumn, data: any, ticker: Ticker, columnOverrides: ITickerColumnOverride[]) {
        let fieldValue: any = this.getFieldValueInternal(column, data);

        const templateOverrides = ColumnTemplateOverrides[ticker.Code] || {};
        const templateOverride = templateOverrides[column.Field];
        if (templateOverride) {
            fieldValue = templateOverride(data, column);
        }

        if (columnOverrides) {
            const columnOverride = columnOverrides.find((x) => x.Field === column.Field);
            if (columnOverride) {
                fieldValue = columnOverride.Template(data);
            }
        }

        if (!fieldValue) {
            fieldValue = column.Placeholder || '';
        }

        let formattedFieldValue = fieldValue;

        const columnType = column.Type;

        switch (column.Type) {
            case 'number':
            case 'money':
            case 'percent':
                fieldValue = fieldValue || 0;
        }

        if (fieldValue !== '') {
            switch (columnType) {
                case 'number':
                    formattedFieldValue = this.numberFormatService.asNumber(fieldValue);
                    break;
                case 'money':
                    formattedFieldValue = this.numberFormatService.asMoney(fieldValue);
                    break;
                case 'percent':
                    formattedFieldValue = this.numberFormatService.asPercentage(fieldValue);
                    break;
                case 'date':
                case 'datetime':
                case 'localdate':
                    formattedFieldValue = rigDate(fieldValue).format('DD.MM.YYYY');
                    break;
            }
        }

        if (column.SelectableFieldName.toLocaleLowerCase().endsWith('entitytype')) {
            const modelName = data[column.Alias];
            if (modelName) {
                const translatedName = modelTranslations[modelName] || '';
                const linkNavigationPropertyAlias = column.LinkNavigationProperty.replace('.', '');
                formattedFieldValue = `${translatedName} #${data[linkNavigationPropertyAlias]}`;
            }
        }

        if (column.SelectableFieldName.toLocaleLowerCase().endsWith('entitydisplayvalue')) {
            const modelName = data['SharingEntityType'];
            if (modelName) {
                const translatedName = modelTranslations[modelName] || '';
                formattedFieldValue = `${translatedName} ${fieldValue}`;
            }
        }

        if (column.SelectableFieldName.toLowerCase().endsWith('statuscode')) {
            const statusCodeText = this.statusCodeToText(data[column.Alias]);
            formattedFieldValue = this.statusCodeOverrides(statusCodeText, ticker.Code, data);
        }

        if (column.SubFields && column.SubFields.length > 0) {
            column.SubFields.forEach((sf) => {
                const subFieldValue = this.getFieldValue(sf, data, ticker, columnOverrides);
                if (subFieldValue && subFieldValue !== '') {
                    formattedFieldValue += column.Seperator ? column.Seperator + subFieldValue : ' - ' + subFieldValue;
                }
            });
        }

        return formattedFieldValue;
    }

    linkColUrlResolver(column: TickerColumn, data: any, ticker: Ticker): string {
        let fieldValue = this.getFieldValueInternal(column, data) || column.Placeholder || '';

        if (column.Type === 'link') {
            const sourceModelName = column.ExternalModel ?? data?.TracelinkSourceEntityName;

            if (sourceModelName === 'JournalEntry') {
                return this.getJournalEntryLink(column, data);
            }

            let modelName = sourceModelName || ticker.Model;

            if (column.Field === 'EntityDisplayValue' && ticker.Code === 'sharings_list') {
                modelName = data['SharingEntityType'];
            }

            let url = linkUrls[modelName];

            if (modelName === 'CustomerInvoiceReminder') {
                url += '?view=reminder-log';
            }

            if (column.LinkNavigationProperty) {
                const linkNavigationPropertyAlias = column.LinkNavigationProperty.replace('.', '');
                if (data[linkNavigationPropertyAlias]) {
                    url = url.replace(':ID', data[linkNavigationPropertyAlias]);
                } else {
                    // we dont have enough data to link to the external model, just show
                    // the property as a normal field
                    url = '';
                }
            } else if (column.LinkNavigationProperties && column.LinkNavigationProperties.length) {
                column.LinkNavigationProperties.forEach((prop) => {
                    url = url.replace(`:${prop.toLowerCase().replace('.', '')}`, data[prop.replace('.', '')]);
                });
            } else {
                if (data['ID']) {
                    url = url.replace(':ID', data['ID']);
                }
            }

            return url;
        } else if (column.Type === 'external-link') {
            return fieldValue;
        } else if (column.Type === 'mailto') {
            return 'mailto:' + fieldValue;
        }
    }

    private getJournalEntryLink(column: TickerColumn, data) {
        const cellValue = (data[column.Alias] || '').toString();

        if (column.Field == 'SourceInstanceID') {
            return `/accounting/transquery?JournalEntryID=${cellValue}`;
        }

        let [number, year] = cellValue.split('-');

        if (!year) {
            year = new Date().getFullYear();

            // Try to find the year in another field. Dont use delivery or due dates, as these may vary
            for (const key in data) {
                const keyLowerCase = key?.toLowerCase() || '';
                if (
                    keyLowerCase.includes('date') &&
                    !keyLowerCase.includes('due') &&
                    !keyLowerCase.includes('delivery')
                ) {
                    year = rigDate(data[key]).year();
                }
            }
        }

        return `/accounting/transquery?JournalEntryNumber=${number}&AccountYear=${year}`;
    }

    private statusCodeToText(statusCode: number): string {
        const text: string = this.statusService.getStatusText(statusCode);
        return text || (statusCode ? statusCode.toString() : '');
    }

    public statusCodeOverrides(text, tickerCode, data): string {
        if (tickerCode === 'invoice_list' && data.CustomerInvoiceInvoiceType === InvoiceTypes.CreditNote) {
            return 'Kreditnota';
        }
        return text;
    }

    private getFieldValueInternal(column: TickerColumn, model: any): any {
        let fieldName = column.Field;
        let fieldValue: string = null;

        // try to get the value using the alias, this will normally be the correct
        // thing to do
        if (column.Alias) {
            fieldValue = model[column.Alias];
            return fieldValue;
        }

        // if Alias is not set on the column, try to get a value using the fieldname. This
        // involves more analysis of the field name, e.g. Customer.Info.Name is probably
        // queried as InfoName
        fieldValue = model[fieldName];

        if (fieldValue) {
            return fieldValue;
        }

        if (fieldName.indexOf('.') !== -1) {
            const colName = fieldName.substring(fieldName.lastIndexOf('.') + 1);
            let lastPath = fieldName.substring(0, fieldName.lastIndexOf('.'));

            if (lastPath.indexOf('.') !== -1) {
                lastPath = lastPath.substring(lastPath.lastIndexOf('.') + 1);
            }

            fieldName = lastPath + colName;
            fieldValue = model[fieldName];
            if (fieldValue) {
                return fieldValue;
            }
        }

        return '';
    }

    public getFilterString(
        filterGroups: TickerFilterGroup[],
        expressionFilterValues: IExpressionFilterValue[],
        useAllCriterias: boolean,
        mainModel: string,
    ): string {
        let filterString: string = '';
        let isInGroup: boolean = false;
        let lastGroupWasUsed: boolean = false;

        for (let groupIndex = 0; groupIndex < filterGroups.length; groupIndex++) {
            const group = filterGroups[groupIndex];
            let filters = group.FieldFilters;

            // dont use filters that miss either field or operator - this is probably just a filter
            // the user has not finished constructing yet
            if (filters) {
                filters = filters.filter((x) => x.Field && x.Field !== '' && x.Operator && x.Operator !== '');
            }

            if (filters && filters.length > 0) {
                const orderedByGroupFilters = filters.sort((a, b) => a.QueryGroup - b.QueryGroup);
                isInGroup = false;

                let groupFilterString: string = '';
                let needsDelimiterBeforeNextFilter: boolean = false;

                for (let index = 0; index < orderedByGroupFilters.length; index++) {
                    const filter: TickerFieldFilter = orderedByGroupFilters[index];
                    const filterValue: string = this.getFilterValueFromFilter(filter, expressionFilterValues);

                    if (filterValue || filterValue === '') {
                        // open new filter group with parenthesis
                        if (!isInGroup) {
                            groupFilterString += '(';
                            isInGroup = true;
                        }

                        // add "or" or "and" between groups depending on the UseAllCriterias flag
                        if (groupFilterString !== '' && needsDelimiterBeforeNextFilter) {
                            if (!group.UseAllCriterias) {
                                groupFilterString += ' or ';
                            } else {
                                groupFilterString += ' and ';
                            }

                            needsDelimiterBeforeNextFilter = false;
                        }

                        if (
                            filter.Operator === 'contains' ||
                            filter.Operator === 'startswith' ||
                            filter.Operator === 'endswith'
                        ) {
                            // Function operator
                            groupFilterString += `${filter.Operator}(${filter.Field},'${filterValue}')`;
                        } else {
                            // Logical operator
                            if (!this.isFunction(filter.Field)) {
                                groupFilterString += `${filter.Field} ${filter.Operator} '${filterValue}'`;
                            } else {
                                // field is a function, trust the user knows what he is doing..
                                groupFilterString += `${filter.Field} ${filter.Operator} '${filterValue}'`;
                            }
                        }

                        needsDelimiterBeforeNextFilter = true;
                    }
                }

                // add "or" or "and" between groups
                if (groupFilterString !== '') {
                    // close group if we are in a group
                    if (isInGroup) {
                        groupFilterString += ' )';
                        isInGroup = false;
                    }

                    if (groupIndex > 0 && !useAllCriterias && lastGroupWasUsed) {
                        filterString += ' or ' + groupFilterString;
                    } else if (groupIndex > 0 && lastGroupWasUsed) {
                        filterString += ' and ' + groupFilterString;
                    } else {
                        filterString += groupFilterString;
                    }

                    lastGroupWasUsed = true;
                } else {
                    lastGroupWasUsed = false;
                }
            }
        }

        return filterString;
    }

    private getFilterValueFromFilter(
        filter: TickerFieldFilter,
        expressionFilterValues: IExpressionFilterValue[],
    ): string {
        let filterValue = filter.Value ? filter.Value.toString() : '';

        // if expressionfiltervalues are defined, e.g. ":currentuserid", check if any of the defined filters
        // should inject the expressionfiltervalue
        if (filterValue.toString().startsWith(':')) {
            const expressionFilterValue = expressionFilterValues.find((efv) => ':' + efv.Expression === filterValue);

            if (expressionFilterValue) {
                filterValue = expressionFilterValue.Value;
            } else {
                // console.log('No ExpressionFilterValue defined for filterexpression ' + filterValue);
            }
        }

        return filterValue;
    }

    // Search value and text showing in the dropdown in advanced search
    public getSelectConfigOptions(configKey: string) {
        switch (configKey) {
            case 'SharingType':
                return [
                    { ID: 0, Name: 'Bruk utsendelsesplan' },
                    { ID: SharingType.AP, Name: 'Aksesspunkt' },
                    { ID: SharingType.Email, Name: 'E-post' },
                    { ID: SharingType.Export, Name: 'Eksport' },
                    { ID: SharingType.Vipps, Name: 'Vipps faktura' },
                    { ID: SharingType.Print, Name: 'Utskrift' },
                    { ID: SharingType.InvoicePrint, Name: 'DISTRIBUTION.INVOICE_PRINT' },
                    { ID: SharingType.Factoring, Name: 'Factoring' },
                    { ID: SharingType.Efaktura, Name: 'Efaktura' },
                    { ID: SharingType.Avtalegiro, Name: 'Avtalegiro' },
                ];

            case 'SharingStatusCode':
                return [
                    { ID: StatusCodeSharing.Pending, Name: 'I kø' },
                    { ID: StatusCodeSharing.InProgress, Name: 'Behandles' },
                    { ID: StatusCodeSharing.Failed, Name: 'Feilet' },
                    { ID: StatusCodeSharing.Completed, Name: 'Fullført' },
                    { ID: StatusCodeSharing.Cancelled, Name: 'Avbrutt' },
                ];
            case 'PrintStatus':
                return [
                    { Name: 'Sendt på e-post', ID: 100 },
                    { Name: 'Sendt til utskrift', ID: 200 },
                    { Name: 'Sendt til aksesspunkt', ID: 300 },
                ];
            case 'TypeOfEmployment':
                return [
                    { ID: 0, Name: 'Ikke valgt' },
                    { ID: TypeOfEmployment.OrdinaryEmployment, Name: '1 - Ordinært arbeidsforhold' },
                    { ID: TypeOfEmployment.MaritimeEmployment, Name: '2 - Maritimt arbeidsforhold' },
                    {
                        ID: TypeOfEmployment.FrilancerContratorFeeRecipient,
                        Name: '3 - Frilanser, oppdragstager, honorar',
                    },
                    {
                        ID: TypeOfEmployment.PensionOrOtherNonEmployedBenefits,
                        Name: '4 - Pensjon og annet uten ansettelse',
                    },
                ];
            case 'RemunerationType':
                return [
                    { ID: RemunerationType.notSet, Name: 'Ikke valgt' },
                    { ID: RemunerationType.FixedSalary, Name: '1 - Fast lønnet' },
                    { ID: RemunerationType.HourlyPaid, Name: '2 - Timelønnet' },
                    { ID: RemunerationType.PaidOnCommission, Name: '3 - Provisjonslønnet' },
                    { ID: RemunerationType.OnAgreement_Honorar, Name: '4 - Honorar' },
                    { ID: RemunerationType.ByPerformance, Name: '5 - Akkord' },
                ];
            case 'WorkingHoursScheme':
                return [
                    { ID: 0, Name: 'Ikke valgt' },
                    { ID: WorkingHoursScheme.NonShift, Name: '1 - Ikke skiftarbeid' },
                    { ID: WorkingHoursScheme.OffshoreWork, Name: '2 - Arbeid offshore' },
                    { ID: WorkingHoursScheme.ContinousShiftwork336, Name: '3 - Helkontinuerlig skiftarbeid' },
                    { ID: WorkingHoursScheme.DayAndNightContinous355, Name: '4 - Døgnkontinuerlig skiftarbeid' },
                    { ID: WorkingHoursScheme.ShiftWork, Name: '5 - skiftarbeid' },
                ];
            case 'ShipType':
                return [
                    { ID: ShipTypeOfShip.notSet, Name: 'Ikke valgt' },
                    { ID: ShipTypeOfShip.Other, Name: '1 - Annet' },
                    { ID: ShipTypeOfShip.DrillingPlatform, Name: '2 - Boreplattform' },
                    { ID: ShipTypeOfShip.Tourist, Name: '3 - Turist' },
                ];
            case 'ShipReg':
                return [
                    { ID: ShipRegistry.notSet, Name: 'Ikke valgt' },
                    {
                        ID: ShipRegistry.NorwegianInternationalShipRegister,
                        Name: '1 - Norsk Internasjonalt skipsregister (NIS)',
                    },
                    { ID: ShipRegistry.NorwegianOrdinaryShipRegister, Name: '2 - Norsk ordinært skipsregister (NOR)' },
                    { ID: ShipRegistry.ForeignShipRegister, Name: '3 - Utenlandsk skipsregister (UTL)' },
                ];
            case 'LeaveType':
                return [
                    { ID: Leavetype.NotSet, Name: 'Ikke valgt' },
                    { ID: Leavetype.Leave, Name: 'Permisjon' },
                    { ID: Leavetype.LayOff, Name: 'Permittering' },
                    { ID: Leavetype.Leave_with_parental_benefit, Name: 'Permisjon med foreldrepenger' },
                    { ID: Leavetype.Military_service_leave, Name: 'Permisjon ved militærtjeneste' },
                    { ID: Leavetype.Educational_leave, Name: 'Utdanningspermisjon - Utgått' },
                    { ID: Leavetype.Compassionate_leave, Name: 'Velferdspermisjon - Utgått' },
                    { ID: Leavetype.Vacation, Name: 'Ferie' },
                    { ID: Leavetype.Self_report, Name: 'Egenmelding' },
                    { ID: Leavetype.Sick_leave_paid, Name: 'Sykemelding' },
                    { ID: Leavetype.Sick_leave_unpaid, Name: 'Ubetalt sykefravær' },
                    { ID: Leavetype.Sick_child, Name: 'Sykt barn' },
                    { ID: Leavetype.Educational_leave_not_by_law, Name: 'Utdanningspermisjon (Ikke lovfestet)' },
                    { ID: Leavetype.Educational_leave_by_law, Name: 'Utdanningspermisjon (Lovfestet)' },
                    { ID: Leavetype.Compassionate_leave_not_by_law, Name: 'Andre ikke-lovfestede permisjoner' },
                    { ID: Leavetype.Compassionate_leave_by_law, Name: 'Andre lovfestede permisjoner' },
                ];

            case 'TaxType':
                return [
                    { ID: TaxType.Tax_None, Name: 'Ingen' },
                    { ID: TaxType.Tax_Table, Name: 'Tabelltrekk' },
                    { ID: TaxType.Tax_Percent, Name: 'Prosenttrekk' },
                    { ID: TaxType.Tax_0, Name: 'Trekkplikt uten skattetrekk' },
                ];
            case 'LimitType':
                return [
                    { Type: LimitType.None, Name: 'Ingen' },
                    { Type: LimitType.Amount, Name: 'Antall' },
                    { Type: LimitType.Amount, Name: 'Beløp' },
                ];
            case 'SpecialAgaRule':
                return [
                    { ID: SpecialAgaRule.Regular, Name: 'Vanlig' },
                    { ID: SpecialAgaRule.AgaRefund, Name: 'Aga refusjon' },
                    { ID: SpecialAgaRule.AgaPension, Name: 'Aga pensjon' },
                    { ID: SpecialAgaRule.AgaRefund5Percent, Name: 'Aga refusjon (påvirker 5% Aga)' },
                ];
            case 'SpecialTaxAndContributionsRule':
                return [
                    { ID: SpecialTaxAndContributionsRule.Standard, Name: 'Standard/ingen valgt' },
                    { ID: SpecialTaxAndContributionsRule.NettoPayment, Name: 'Netto lønn' },
                    {
                        ID: SpecialTaxAndContributionsRule.SpesialDeductionForMaritim,
                        Name: 'Særskilt fradrag for sjøfolk',
                    },
                    { ID: SpecialTaxAndContributionsRule.Svalbard, Name: 'Svalbard' },
                    {
                        ID: SpecialTaxAndContributionsRule.PayAsYouEarnTaxOnPensions,
                        Name: 'Kildeskatt for pensjonister',
                    },
                    { ID: SpecialTaxAndContributionsRule.JanMayenAndBiCountries, Name: 'Jan Mayen og bilandene' },
                    { ID: SpecialTaxAndContributionsRule.NettoPaymentForMaritim, Name: 'Nettolønn for sjøfolk' },
                ];
            case 'StandardWageTypeFor':
                return [
                    { ID: StdWageType.None, Name: 'Ingen' },
                    { ID: StdWageType.TaxDrawTable, Name: 'Tabelltrekk' },
                    { ID: StdWageType.TaxDrawPercent, Name: 'Prosenttrekk' },
                    { ID: StdWageType.HolidayPayWithTaxDeduction, Name: 'Feriepenger med skattetrekk' },
                    { ID: StdWageType.HolidayPayThisYear, Name: 'Feriepenger i år' },
                    { ID: StdWageType.HolidayPayLastYear, Name: 'Feriepenger forrige år' },
                    { ID: StdWageType.HolidayPayEarlierYears, Name: 'Feriepenger tidligere år' },
                    { ID: StdWageType.AdvancePayment, Name: 'Forskudd' },
                    { ID: StdWageType.Contribution, Name: 'Bidragstrekk' },
                    { ID: StdWageType.Garnishment, Name: 'Utleggstrekk skatt' },
                    { ID: StdWageType.Outlay, Name: 'Utleggstrekk' },
                    { ID: StdWageType.SourceTaxPension, Name: 'Forskuddstrekk kildeskatt på pensjon' },
                    { ID: StdWageType.AdvanceTaxChildPension, Name: 'Forskuddstrekk barnepensjon' },
                    { ID: StdWageType.EmployeeLedger, Name: 'Ansattreskontro' },
                ];
            case 'WorkTypeSystemType':
                return [
                    { ID: 1, Name: 'Timer' },
                    { ID: 8, Name: 'Utbetalt flex' },
                    { ID: 9, Name: 'Fri med lønn' },
                    { ID: 10, Name: 'Fri' },
                    { ID: 11, Name: 'Fri (flex)' },
                    { ID: 12, Name: 'Overtid' },
                    { ID: 13, Name: 'Ferie' },
                    { ID: 20, Name: 'Sykdom' },
                ];
            case 'PaymentStatus':
                return [
                    { ID: 30112, Name: 'Betalt' },
                    { ID: 30111, Name: 'Delbetalt' },
                    { ID: 30110, Name: 'Overført til bank' },
                    { ID: 30109, Name: 'Ubetalt' },
                    { ID: 30113, Name: 'I betalingsliste' },
                ];
            case 'InvoiceOriginType':
                return [
                    { ID: SupplierInvoiceOriginType.SupplierInvoice, Name: 'Regning' },
                    { ID: SupplierInvoiceOriginType.Receipt, Name: 'Kvittering' },
                    { ID: SupplierInvoiceOriginType.Refund, Name: 'Tilbakebetaling' },
                ];
            case 'ReinvoicedStatusCode':
                return [
                    { ID: StatusCodeReInvoice.Marked, Name: 'Markert for viderefakturering' },
                    { ID: StatusCodeReInvoice.Ready, Name: 'Klar for viderefakturering' },
                    { ID: StatusCodeReInvoice.ReInvoiced, Name: 'Viderefakturert' },
                    { ID: 30204, Name: 'Delvis viderefakturert' },
                ];
            default:
                return [];
        }
    }
}

export interface TickerGroup {
    Name: string;
    Tickers: Array<Ticker>;
}

export interface TickerQuickFilter extends QuickFilter {
    checkBoxValues?: {
        true: string;
        false: string;
    };
}

export interface Ticker {
    Name: string;
    Code: string;
    Type?: string;
    Group: string;
    IsTopLevelTicker: boolean;
    AvoidAutoExpand?: boolean;
    HideCounter?: boolean;
    Model: string;
    OrderBy?: string;
    Select?: string;
    Expand?: string;
    Distinct?: boolean;
    CountExpand?: string;
    Joins?: string;
    CountJoins?: string;
    ListObject?: string;
    DisableFiltering?: boolean;
    Columns: Array<TickerColumn>;
    ParentFilter?: Array<TickerFieldFilter> | TickerFieldFilter;
    SubTickers?: Array<Ticker>;
    SubTickersCodes?: Array<string>;
    Filter?: string;
    Filters?: Array<TickerFilter>;
    QuickFilters?: TickerQuickFilter[];
    UseParentTickerActions?: boolean;
    Actions?: Array<TickerAction>;
    Pagesize?: number;
    IsActive?: boolean;
    ReadOnlyCases?: { Key: string; Value: any }[];
    MultiRowSelect?: boolean;
    RequiredUIPermissions?: string[];
    DefaultTabIndex?: number;
    AddCustomDimColumns?: boolean;
    GroupingDefaultOn?: boolean;
    ShowCommentColumn?: boolean;
}

export interface TickerFieldFilter {
    Path: string;
    Field: string;
    Operator: string;
    Value: string;
    Value2: string;
    QueryGroup: number;
}

export interface IExpressionFilterValue {
    Expression: string;
    Value: string;
}

export interface TickerColumn {
    Header?: string;
    Field: string;
    FeaturePermission?: string;
    UiPermission?: string;
    IsBankRuleField?: boolean;
    SelectableFieldName?: string;
    Format?: string;
    Width?: string;
    Resizeable?: boolean;
    DefaultHidden?: boolean;
    ShowOnlyOnGivenFilters?: string[];
    ExcludeFromEnvironments?: string[];
    DefaultHiddenOnGivenFilters?: string[];
    DefualtShowOnlyOnGivenFilters?: string[];
    CssClass?: string;
    Type?: string;
    SumFunction?: string;
    Alias?: string;
    ExternalModel?: string;
    LinkNavigationProperty?: string;
    LinkNavigationProperties?: string[];
    FilterOperator?: string;
    SubFields?: Array<TickerColumn>;
    SubFieldsFilterable?: boolean;
    Placeholder?: string;
    FieldSetColumn?: number;
    SumColumn?: boolean;
    MarkedRowsSumCol?: boolean;
    ReadOnlyCases?: { Key: string; Value: any }[];
    DisplayField?: string;
    Expand?: string;
    FilterSelectConfigKey?: string;
    SelectRequired?: boolean;
    Alignment?: 'left' | 'right' | 'center';
    Seperator?: string;
    Stuff?: boolean;
    DisableSort?: boolean;
    SortFields?: string[];
}

export interface ITickerColumnOverride {
    Field: string;
    Template?: (data: any) => string;
}

export class TickerFilterGroup {
    public QueryGroup: number;
    public FieldFilters: Array<TickerFieldFilter>;
    public UseAllCriterias: boolean = true;
}

export class TickerFilter {
    public Name: string;
    public Code: string;
    public Filter: string;
    public OrderBy?: string;
    public IsActive: boolean;
    public tooltip?: string;
    public FilterGroups: Array<TickerFilterGroup>;
    public UseAllCriterias: boolean = true;
    public CurrentCount?: number;
    public IsMultiRowSelect: boolean = false;
    public featurePermission?: string;
    public hidden?: boolean;
    public canGenerateHash?: boolean;
    public hideTooltipWithoutCount?: boolean;
    public hideTabWithEmptyCount?: boolean;
}

export interface TickerAction {
    Name: string;
    Code: string;
    FeaturePermission?: string;
    Type: string;
    ConfirmBeforeExecuteMessage?: string;
    ExecuteWithMultipleSelections?: boolean;
    ExecuteWithoutSelection?: boolean;
    DisplayInContextMenu?: boolean;
    DisplayInActionBar?: boolean;
    NeedsActionOverride?: boolean;
    SendParentModel?: boolean;
    Options: TickerActionOptions;
    Route?: String;
    GoToUrlTarget?: string;
    DisplayOnlyInFilterCode?: string;
}

export interface TickerActionOptions {
    ParameterProperty?: string;
    ParameterProperties?: string[];
    Action?: string;
    Transition?: string;
    ReportName?: string;
}

export interface ITickerActionOverride {
    Code: string;
    CheckActionIsDisabled?: (selectedRow: any) => boolean;
    BeforeExecuteActionHandler?: (selectedRows: Array<any>) => Promise<boolean> | boolean;
    ExecuteActionHandler?: (selectedRows: Array<any>) => Promise<any>;
    AfterExecuteActionHandler?: (selectedRows: Array<any>) => Promise<any>;
}

export interface TickerHistory {
    Url: string;
    TickerCode: string;
    TickerName: string;
    TickerFilterCode: string;
    TickerFilterName: string;
}

const modelTranslations = {
    CustomerInvoice: 'Faktura',
    CustomerOrder: 'Ordre',
    CustomerQuote: 'Tilbud',
    Customer: 'Kunde',
    Product: 'Produkt',
    RecurringInvoice: 'Repeterende faktura',
    Contact: 'Kontakt',
    Supplier: 'Leverandør',
    SupplierInvoice: 'Leverandørfaktura',
    JournalEntry: 'Bilag',
    JournalEntryLine: 'Bilagslinjer',
    Worker: 'Timefører',
    WorkType: 'Timeart',
    WorkProfile: 'Stillingsmaler',
    Employee: 'Ansatt',
    Employment: 'Arbeidsforhold',
    EmployeeLeave: 'Permisjoner',
    WageType: 'Lønnsart',
    PayrollRun: 'Lønnsavregninger',
    SalaryTransaction: 'Lønnsposter',
    CustomerInvoiceReminder: 'Purring',
    Payment: 'Betaling',
    Barnepass: 'Barnepass',
    SelfEmployed: 'Selvstendig næringsdrivende',
};

const linkUrls = {
    CustomerInvoice: '/sales/invoices/:ID',
    CustomerOrder: '/sales/orders/:ID',
    CustomerQuote: '/sales/quotes/:ID',
    Customer: '/sales/customer/:ID',
    Product: '/sales/products/:ID',
    RecurringInvoice: '/sales/recurringinvoice/:ID',
    Contact: '/contacts/:ID',
    Supplier: '/accounting/suppliers/:ID',
    SupplierInvoice: '/accounting/bills/:ID',
    JournalEntry: '/accounting/journalentry/manual;journalEntryID=:journalentryid',
    JournalEntryLine: '/accounting/journalentry/manual;journalEntryID=:journalentryid',
    Worker: '/timetracking/workers/:ID',
    WorkType: '/timetracking/worktypes/:ID',
    WorkProfile: '/timetracking/workprofiles/:ID',
    Employee: '/salary/employees/:ID',
    Employment: '/salary/employees/:employmentemployeeid/employments/:id',
    WageType: '/salary/wagetypes/:ID',
    PayrollRun: '/salary/payrollrun/:ID',
    CustomerInvoiceReminder: '/sales/reminders',
    Payment: '/bank',
    Barnepass: '/altinn/childcare',
    SelfEmployed: '/altinn/selfemployed',
    Project: '/dimensions/projects/:ID',
};
