<ng-container *ngIf="ticker && prefetchDataLoaded && canShowTicker">
    <!-- Main ticker header -->
    <section *ngIf="!parentModel; else subTickerHeader" class="ticker-horizontal-menu">
        <a
            *ngFor="let action of getTickerActions()"
            (click)="onExecuteAction(action)"
            [hidden]="action.DisplayInActionBar === false"
        >
            {{ action.Name }}
        </a>
    </section>
    <!-- Sub ticker header (ng else) -->
    <ng-template #subTickerHeader>
        <header class="sub-ticker-header">
            <span class="ticker-title">{{ ticker.Name | translate }}</span>

            <uni-context-menu *ngIf="contextMenuItems?.length" [actions]="contextMenuItems" class="ticker-context-menu">
            </uni-context-menu>

            <button
                *ngIf="openAction"
                type="button"
                class="tertiary"
                style="margin-left: auto"
                (click)="onExecuteAction(openAction)"
            >
                <i class="material-icons">edit</i>
                Åpne
            </button>
        </header>
    </ng-template>

    <ag-grid-wrapper
        *ngIf="ticker.Type === 'table' && !groupingIsOn"
        class="borders"
        [resource]="lookupFunction"
        [columnSumResolver]="columnSumResolver"
        [config]="tableConfig"
        (columnsChange)="onColumnsChange($event)"
        (rowSelectionChange)="onRowSelectionChange($event)"
        (dataLoaded)="dataLoaded($event)"
        (rowClick)="onRowClick($event)"
    >
    </ag-grid-wrapper>

    <ag-grid-wrapper
        *ngIf="ticker.Type === 'table' && groupingIsOn"
        class="borders"
        [resource]="resource"
        [columnSumResolver]="columnSumResolver"
        [config]="tableConfig"
        (rowSelectionChange)="onRowSelectionChange($event)"
        (filtersChange)="onGroupingTableFiltersChange($event)"
        (rowClick)="onRowClick($event)"
    >
    </ag-grid-wrapper>

    <div *ngIf="ticker.Type !== 'table'">
        <uni-ticker-detail-view [ticker]="ticker" [model]="model" [columnOverrides]="columnOverrides">
        </uni-ticker-detail-view>
    </div>
</ng-container>
