<section class="ticker-overview">
    <ul class="ticker-list">
        <li *ngFor="let group of tickerGroups">
            {{ group.Name }}
            <ul>
                <li
                    *ngFor="let ticker of group.Tickers"
                    (click)="navigateToTicker(ticker)"
                    [attr.aria-selected]="ticker.Code === selectedTicker?.Code"
                >
                    {{ ticker.Name | translate }}
                </li>
            </ul>
        </li>
    </ul>

    <section class="overview-ticker-section">
        <section class="overview-ticker-header">
            <span class="ticker-title">{{ tickerTitle || 'Oversikt' | translate }}</span>

            <section class="ticker-actions pull-right">
                <button class="tertiary" (click)="turnGroupingOnOff()">Slå av/på gruppering</button>

                <button class="secondary" *ngIf="createNewAction" (click)="onCreateNewClick()">Opprett ny</button>

                <button class="c2a" (click)="exportToExcel()">Eksporter til excel</button>
            </section>
        </section>

        <uni-ticker-container
            *ngIf="!navigatingToTicker"
            [ticker]="selectedTicker"
            [actionOverrides]="actionOverrides"
            (urlParamsChange)="onTickerParamsChange($event)"
        >
        </uni-ticker-container>
    </section>
</section>
